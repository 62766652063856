import React, { useEffect, useState } from 'react'
import Client from '../Client'
import { useParams } from 'react-router-dom'
import PortableText from '@sanity/block-content-to-react'
import styled from 'styled-components'

const Cont = styled.div`
	width: 70%;
	padding: 100px 5%;
	height: auto;
	text-align: left;
`

const Policy = () => {
	let id = useParams()
	const [policy, setPolicy] = useState('')

	useEffect(() => {
		Client.fetch(`*[_type == "integritetspolicy"]`).then(policy => {
			const filteredPolicy = policy[0].processer.filter(process => {
				return (
					process.titel
						.toLowerCase()
						.replace(/ /g, '-')
						.replace(/å/g, 'a')
						.replace(/ä/g, 'a')
						.replace(/ö/g, 'o')
						.replace(/[^\w-]+/g, '') === id.policyId
				)
			})

			const filteredPersonuppgifter = policy[0].personuppgifter.filter(
				process =>
					process.titel
						.toLowerCase()
						.replace(/ /g, '-')
						.replace(/å/g, 'a')
						.replace(/ä/g, 'a')
						.replace(/ö/g, 'o')
						.replace(/[^\w-]+/g, '') === id.policyId
			)
			setPolicy(filteredPolicy[0]?.policy || filteredPersonuppgifter[0]?.policy)
		})
	}, [id])

	return <Cont>{policy && <PortableText blocks={policy} />}</Cont>
}

export default Policy
