import React, { lazy, Suspense, useState, useEffect } from 'react'
import { Route, Switch } from 'react-router-dom'
import './App.css'
import { ThemeProvider } from 'styled-components'
import { theme } from './theme.styles'
import Header from './components/header/header.component'
import Footer from './components/footer/footer-component'
import Policy from './pages/policy.component'
import sanityClient from './Client'
import styled from 'styled-components'

const HomePage = lazy(() => import('./pages/home.component'))
const ArticlePage = lazy(() => import('./pages/article.component'))
const ErfarenhetPage = lazy(() =>
	import('./components/erfarenhet-extended/erfarenhet-extended.component')
)
const KonsultPage = lazy(() =>
	import('./components/konsult-extended-phone/konsult-extended-phone.component')
)
const Terms = lazy(() => import('./pages/terms.component'))

function App() {
	const [home, setHome] = useState({
		thumbnail: '',
		titel: '',
	})
	const [contact, setContact] = useState('')
	const [tjanster, setTjanster] = useState('')
	const [roller, setRoller] = useState('')
	const [konsult, setKonsult] = useState([])
	const [omOss, setOmOss] = useState('')
	const [nyheter, setNyheter] = useState('')
	const [erfarenhet, setErfarenhet] = useState('')

	useEffect(() => {
		const homeQuery = `*[_type == "artikel" && slider] | order(datum desc) {
        thumbnail, titel
    }`
		const contactQuery = `*[_type == "kontakt"]`
		const tjansterQuery = `*[_type == "artikel" && tjanster] | order(datum desc)
        {
            thumbnail, titel
        }`
		const rollQuery = `*[_type == "roller"]`
		const omOssQuery = `*[_type == "omOss"]`
		const articleQuery = `*[_type == "artikel" && nyhet] | order(datum desc)
    {
        thumbnail, titel
    }`
		const erfarenhetQuery = `*[_type == "erfarenhet"] | order(datum desc)
    {
        thumbnail, titel
    }`
		const konsultQuery = `*[_type == "konsult"] | order(namn asc)`

		sanityClient.fetch(homeQuery).then(home => {
			const homeArray = []
			home.forEach(home => {
				homeArray.push(home)
			})
			setHome(homeArray)
		})

		sanityClient.fetch(contactQuery).then(contact => {
			contact.forEach(contact => {
				setContact(contact)
			})
		})

		const tjansterArray = []

		sanityClient.fetch(tjansterQuery).then(article => {
			article.forEach(article => {
				tjansterArray.push(article)
			})
			setTjanster(tjansterArray)
		})

		sanityClient.fetch(rollQuery).then(roller => {
			roller.forEach(roll => {
				setRoller(roll)
			})
		})

		sanityClient.fetch(omOssQuery).then(omOss => {
			omOss.forEach(omOss => {
				setOmOss(omOss)
			})
		})

		const articleArray = []

		sanityClient.fetch(articleQuery).then(article => {
			article.forEach(article => {
				articleArray.push(article)
			})
			setNyheter(articleArray)
		})

		const erfarenhetArray = []

		sanityClient.fetch(erfarenhetQuery).then(article => {
			article.forEach(article => {
				erfarenhetArray.push(article)
			})
			setErfarenhet(erfarenhetArray)
		})

		const konsultArray = []
		sanityClient.fetch(konsultQuery).then(konsult => {
			konsult.forEach(konsult => {
				konsultArray.push(konsult)
			})
			setKonsult(konsultArray)
		})
		return
	}, [])

	const Fallback = styled.div`
		display: flex;
		height: 100vh;
		width: 100%;
		justify-content: center;
		align-items: center;
	`
	return (
		<div className="App">
			<ThemeProvider theme={theme}>
				<Header />

				<Suspense
					fallback={
						<Fallback>
							<p></p>
						</Fallback>
					}
				>
					<Switch>
						<Route path={'/'} exact>
							<HomePage
								erfarenhet={erfarenhet}
								home={home}
								tjanster={tjanster}
								nyheter={nyheter}
								omOss={omOss}
								contact={contact}
								konsult={konsult}
								roller={roller}
							/>
						</Route>
						<Route path={'/artikel/:artikelId'} component={ArticlePage} />
						<Route path={'/erfarenhet/:artikelId'} component={ErfarenhetPage} />
						<Route path={'/konsult/:artikelId'} component={KonsultPage} />

						<Route path={'/integritetspolicy/:policyId'} component={Policy} />
						<Route exact path={'/integritetspolicy'} component={Terms} />
					</Switch>
				</Suspense>

				<Footer />
			</ThemeProvider>
		</div>
	)
}

export default App
